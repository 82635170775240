import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

import { BoardOrCommissionDetails } from '../boardsAndCommissionsSlice';

export interface BoardOrCommissionMember {
    Full_Name__c: string;
    Attendance_Percentage__c: number;
}

export interface BoardOrCommissionAttendance {
    Meeting_Date__c: string;
    Member__r: {
        Full_Name__c: string;
    };
    Attendance__c: string;
}

export interface Meeting {
    date: string;
    members: MeetingMember[];
}

export interface MeetingMember {
    name: string;
    attendance: string;
}

export interface AdditionalInformation {
    label: string;
    url: string;
}

const initialState = {
    detail: {
        Staff_Liaison__r: {
            Name: '',
        },
        Powers_Duties__c: '',
    } as BoardOrCommissionDetails,
    members: [] as BoardOrCommissionMember[],
    meetings: [] as Meeting[],
    meetingDetails: {
        date: '',
        members: [],
    } as Meeting,
    additionalInformation: [] as AdditionalInformation[],
    modal: false,
    loading: false,
};

export type BoardOrCommissionViewAppState = typeof initialState;

export const slice = createSlice<
    BoardOrCommissionViewAppState,
    {
        setDetail: CaseReducer<
            BoardOrCommissionViewAppState,
            PayloadAction<BoardOrCommissionDetails>
        >;
        setMembers: CaseReducer<
            BoardOrCommissionViewAppState,
            PayloadAction<BoardOrCommissionMember[]>
        >;
        setMeetings: CaseReducer<
            BoardOrCommissionViewAppState,
            PayloadAction<Meeting[]>
        >;
        setMeetingDetails: CaseReducer<
            BoardOrCommissionViewAppState,
            PayloadAction<Meeting>
        >;
        setAdditonalInformation: CaseReducer<
            BoardOrCommissionViewAppState,
            PayloadAction<AdditionalInformation[]>
        >;
        setModal: CaseReducer<
            BoardOrCommissionViewAppState,
            PayloadAction<boolean>
        >;
        setLoading: CaseReducer<
            BoardOrCommissionViewAppState,
            PayloadAction<boolean>
        >;
    }
>({
    name: 'boardOrCommissionView',
    initialState,
    reducers: {
        setDetail: (state, { payload }) => {
            state.detail = payload;
        },
        setMembers: (state, { payload }) => {
            state.members = payload;
        },
        setMeetings: (state, { payload }) => {
            state.meetings = payload;
        },
        setMeetingDetails: (state, { payload }) => {
            state.meetingDetails = payload;
        },
        setAdditonalInformation: (state, { payload }) => {
            state.additionalInformation = payload;
        },
        setModal: (state, { payload }) => {
            state.modal = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
    },
});

export const useAppStateBoardOrCommissionViewSelector =
    buildSubStateSelector<BoardOrCommissionViewAppState>(
        (state) => state.boardOrCommissionView,
    );
export const boardOrCommissionViewActions = slice.actions;
export const boardOrCommissionViewReducer = slice.reducer;
