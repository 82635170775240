import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { loadBoardOrCommissionDetails } from './boardsAndCommissionsActionCreators';
import {
    ResourcesAndInformationInterface,
    useAppStateBoardsAndCommissionsSelector,
} from './boardsAndCommissionsSlice';

import { PageContainer } from '../../components/pageContainer/PageContainer';
import { Panel } from '../../components/panel/Panel';
import { PanelRow } from '../../components/panel/PanelRow';
import { Icon } from '../../components/icon/Icon';
import { Button } from '../../components/button/Button';
import { Spinner } from '../../components/spinner/Spinner';

import styles from './BoardsAndCommissions.module.scss';

export const BoardsAndCommissions = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { details, loading } = useAppStateBoardsAndCommissionsSelector(
        (state) => state,
    );

    useEffect(() => {
        dispatch(loadBoardOrCommissionDetails());
    }, [dispatch]);

    const handleOnBoardOrCommissionSelect = (id: string) => {
        history.push(`/boards-and-commissions/${id}`);
    };

    const handleOnRedirect = (url: string) => {
        window.open(url, '_blank');
    };

    const Overview = () => {
        return (
            <div className={styles.panelContainer}>
                <div className={styles.detailContainer}>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.detailTitle}>
                            {t('BoardsAndCommissions.OverviewTitle')}
                        </div>
                    </div>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.detailRow}>
                            {t('BoardsAndCommissions.OverviewDescription')}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const ResourcesAndInformation = () => {
        const resourcesAndInformation: ResourcesAndInformationInterface[] = [
            {
                name: `${t(
                    'BoardsAndCommissions.ResourcesAndInformationCommissionerGuide',
                )}`,
                icon: 'file outline',
                url: 'https://elgin.app.box.com/s/xjirm2vruu69cyggjj58o72iqpl4kz9q',
            },
            {
                name: `${t(
                    'BoardsAndCommissions.ResourcesAndInformationUpcomingMeetings',
                )}`,
                icon: 'calendar alternate outline',
                url: 'https://www.cityofelgin.org/calendar.aspx?CID=15',
            },
            {
                name: `${t(
                    'BoardsAndCommissions.ResourcesAndInformationAgendasAndMinutes',
                )}`,
                icon: 'clock outline',
                url: 'https://cityofelgin.org/archive.aspx',
            },
            {
                name: `${t(
                    'BoardsAndCommissions.ResourcesAndInformationApply',
                )}`,
                icon: 'clipboard outline',
                url: 'https://lf-forms.cityofelgin.net/Forms/BoardandCommissionApplication',
            },
            {
                name: `${t(
                    'BoardsAndCommissions.ResourcesAndInformationStudentApplication',
                )}`,
                icon: 'student',
                url: 'https://lf-forms.cityofelgin.net/Forms/StudentRepBCApp',
            },
        ];

        return (
            <div className={styles.panelContainer}>
                <div className={styles.detailContainer}>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.detailTitle}>
                            {t(
                                'BoardsAndCommissions.ResourcesAndInformationTitle',
                            )}
                        </div>
                    </div>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.gridContainer}>
                            {resourcesAndInformation.map((redirect, index) => (
                                <div
                                    className={styles.gridSubContainer}
                                    key={index}
                                >
                                    <div
                                        className={styles.gridItemContainer}
                                        onClick={() =>
                                            handleOnRedirect(redirect.url)
                                        }
                                    >
                                        <div
                                            className={
                                                styles.gridItemSubContainer
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.gridItemIconContainer
                                                }
                                            >
                                                <Icon
                                                    icon={redirect.icon}
                                                    color="blue"
                                                    size="big"
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.gridItemTextContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.gridItemText
                                                    }
                                                >
                                                    {redirect.name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const BoardsAndCommissions = () => {
        return (
            <div className={styles.panelContainer}>
                <div className={styles.detailContainer}>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.detailTitle}>
                            {t(
                                'BoardsAndCommissions.BoardOrCommissionSelectionTitle',
                            )}
                        </div>
                    </div>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.detailDescription}>
                            {t(
                                'BoardsAndCommissions.BoardOrCommissionSelectionDescription',
                            )}
                        </div>
                    </div>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.gridContainer}>
                            {details.map((detail, index) => (
                                <div
                                    className={styles.gridSubContainer}
                                    key={index}
                                >
                                    <div
                                        className={styles.gridItemContainer}
                                        onClick={() =>
                                            handleOnBoardOrCommissionSelect(
                                                detail.Id,
                                            )
                                        }
                                    >
                                        <div
                                            className={
                                                styles.gridItemSubContainer
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.gridItemIconContainer
                                                }
                                            >
                                                <Icon
                                                    icon={detail.Icon__c}
                                                    color="blue"
                                                    size="big"
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.gridItemTextContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.gridItemText
                                                    }
                                                >
                                                    {detail.Alias__c}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const CurrentVacancies = () => {
        return (
            <div className={styles.panelContainer}>
                <div className={styles.detailContainer}>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.detailTitle}>
                            {t('BoardsAndCommissions.CurrentVacanciesTitle')}
                        </div>
                    </div>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.detailDescription}>
                            {t(
                                'BoardsAndCommissions.CurrentVacanciesDescription',
                            )}
                        </div>
                    </div>
                    <div className={styles.detailSubContainer}>
                        <div className={styles.gridContainerVacancies}>
                            {details.map((detail, index) => (
                                <div
                                    className={styles.gridSubContainer}
                                    key={index}
                                >
                                    <div
                                        className={
                                            styles.gridItemContainerVacancies
                                        }
                                    >
                                        <div
                                            className={
                                                styles.gridItemSubContainerVacancies
                                            }
                                        >
                                            <Icon
                                                icon={detail.Icon__c}
                                                color="blue"
                                                size="large"
                                            />
                                            <div
                                                className={
                                                    styles.gridItemTitleVacancies
                                                }
                                            >
                                                {detail.Alias__c}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                styles.gridItemSubContainerVacancies
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.gridItemTextVacancies
                                                }
                                            >
                                                {t(
                                                    'BoardsAndCommissions.CurrentVacanciesCardCountDescription',
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    styles.gridItemCountVacancies
                                                }
                                            >
                                                {detail.Vacancies__c}
                                            </div>
                                        </div>
                                        <CurrentVacanciesApplyOrUnavailable
                                            vacanciesCount={detail.Vacancies__c}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const CurrentVacanciesApplyOrUnavailable = (props: {
        vacanciesCount: number;
    }) => {
        const { vacanciesCount } = props;

        if (vacanciesCount > 0) {
            return (
                <div className={styles.gridItemSubContainerVacancies}>
                    <Button
                        color="green"
                        onClick={() =>
                            handleOnRedirect(
                                'https://lf-forms.cityofelgin.net/Forms/BoardandCommissionApplication',
                            )
                        }
                    >
                        {t(
                            'BoardsAndCommissions.CurrentVacanciesCardButtonAvailable',
                        )}
                    </Button>
                </div>
            );
        } else if (vacanciesCount === 0) {
            return (
                <div className={styles.gridItemSubContainerVacancies}>
                    <div className={styles.gridItemUnavailableVacancies}>
                        {t(
                            'BoardsAndCommissions.CurrentVacanciesCardButtonUnavailable',
                        )}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const Header = () => {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.headerTextContainer}>
                    <h2>{t('Activity.FIND_BOARDS_AND_COMMISSIONS')}</h2>
                </div>
            </div>
        );
    };

    if (loading) {
        return <Spinner isLoading={loading} fullPage />;
    }

    return (
        <PageContainer style={{ maxWidth: 1000, width: '100%' }}>
            <Header />
            <PanelRow>
                <Panel>
                    <Overview />
                </Panel>
            </PanelRow>
            <PanelRow>
                <Panel>
                    <ResourcesAndInformation />
                </Panel>
            </PanelRow>
            <PanelRow>
                <Panel>
                    <BoardsAndCommissions />
                </Panel>
            </PanelRow>
            <PanelRow>
                <Panel>
                    <CurrentVacancies />
                </Panel>
            </PanelRow>
        </PageContainer>
    );
};
