import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { SemanticICONS } from 'semantic-ui-react';

import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

export interface BoardOrCommissionDetails {
    Id: string;
    Name: string;
    Size__c: string;
    Alias__c: string;
    Overview__c: string;
    Staff_Liaison_Email__c: string;
    Staff_Liaison_Phone__c: string;
    Staff_Liaison__r: {
        Name: string;
    };
    Term_Length__c: string;
    Term_Limit__c: string;
    Vacancies__c: number;
    Meeting_Date__c: string;
    Meeting_Time__c: string;
    Meeting_Location__c: string;
    Icon__c: SemanticICONS;
    Powers_Duties__c: string;
    Label_Additional_Information1__c: string;
    Additional_Information1__c: string;
    Label_Additional_Information2__c: string;
    Additional_Information2__c: string;
    Label_Additional_Information3__c: string;
    Additional_Information3__c: string;
    Label_Additional_Information4__c: string;
    Additional_Information4__c: string;
    Label_Additional_Information5__c: string;
    Additional_Information5__c: string;
    Label_Additional_Information6__c: string;
    Additional_Information6__c: string;
    Label_Additional_Information7__c: string;
    Additional_Information7__c: string;
    Label_Additional_Information8__c: string;
    Additional_Information8__c: string;
    Label_Additional_Information9__c: string;
    Additional_Information9__c: string;
    Label_Additional_Information10__c: string;
    Additional_Information10__c: string;
}

export interface ResourcesAndInformationInterface {
    name: string;
    icon: SemanticICONS;
    url: string;
}

const initialState = {
    details: [] as BoardOrCommissionDetails[],
    loading: false,
};

export type BoardsAndCommissionsAppState = typeof initialState;

export const slice = createSlice<
    BoardsAndCommissionsAppState,
    {
        setDetails: CaseReducer<
            BoardsAndCommissionsAppState,
            PayloadAction<BoardOrCommissionDetails[]>
        >;
        setLoading: CaseReducer<
            BoardsAndCommissionsAppState,
            PayloadAction<boolean>
        >;
    }
>({
    name: 'boardsAndCommissions',
    initialState,
    reducers: {
        setDetails: (state, { payload }) => {
            state.details = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
    },
});

export const useAppStateBoardsAndCommissionsSelector =
    buildSubStateSelector<BoardsAndCommissionsAppState>(
        (state) => state.boardsAndCommissions,
    );
export const boardsAndCommissionsActions = slice.actions;
export const boardsAndCommissionsReducer = slice.reducer;
