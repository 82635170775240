import { AxiosService } from './axiosService';
import { AxiosError } from 'axios';
import { User } from '../models/user';
import { firebaseService } from './firebaseService';
import { config } from '../config';
import { SAMLResponse } from '../models/samlResponse';
import { checkUserBrowser } from './utilities';

export class AuthService extends AxiosService {
    sessionKey: string = "recordedUserSessionActivity";

    public constructor() { super(); }

    async getAuthUser() {
        try {
            const {data} = await this.axios.get<User>("/auth");
            return data;
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
        return null;
    }

    getFirebaseUser() {
        return firebaseService.auth().currentUser;
    }

    async signup (email: string, firebaseUserId: string) {
        try {
            const {data} = await this.axios.post("/signup", {email, firebaseUserId});
            return data;
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
        return null;
    }

    async forgotPassword (email: string) {
        const webUrl = config.webUrl;
        const actionCodeSettings: firebaseService.auth.ActionCodeSettings = {
            url: `${webUrl}/dashboard`,
            handleCodeInApp: true,
        };
        await firebaseService.auth().sendPasswordResetEmail(email, actionCodeSettings);
    }

    async sendVerificationLinkToEmail() {
        const webUrl = config.webUrl;
        const actionCodeSettings: firebaseService.auth.ActionCodeSettings = {
            url: `${webUrl}/dashboard`,
            handleCodeInApp: true,
            //? Currently we are handling user signup in browser only, mobile included
            // iOS: {
            //     bundleId: 'org.cityofelgin.mobile',
            // },
            // android: {
            //     packageName: 'com.elginmobile',
            //     installApp: true,
            //     minimumVersion: '12'
            // },
            // dynamicLinkDomain: 'cityofelgin.page.link',
        };

        const user = firebaseService.auth().currentUser;
        console.log('Current Firebase user for email Verification', user);
        console.log('Current Firebase user email', user?.email);

        user?.sendEmailVerification(actionCodeSettings)
            .then(() => {
                console.log('Email Sent');
                return true;
            })
            .catch(err => {
                console.error(err);
                return false;
            });
    }

    async applyActionCode(oobCode: string) {
        await firebaseService.auth().applyActionCode(oobCode)
            .catch(err => {
                console.error(err);
                return false;
            });
        return true;
    }

    async completeEmailVerification() {
        await firebaseService.auth().currentUser?.reload();
        const fbUser = firebaseService.auth().currentUser;
        const dbUser = await this.getAuthUser();

        if (fbUser?.emailVerified && dbUser?.Verified) {
            return true;
        } else if (fbUser?.emailVerified && !dbUser?.Verified) {
            const verified = await this.axios.post('/verify')
                .catch(err => {
                    console.error(err);
                    return false;
                });
            return verified;
        }

        return false;
    }

    async assertSAMLRequest(samlRequest: string | undefined) {
        try {
            const {data} = await this.axios.post<SAMLResponse>("/sso/SAML", {
                samlRequest
            });
            return data;
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
    }

    async recordUserSession(isLoggingIn: boolean = false) {
        try {
            // Check if the user has re-opened the site OR has just logged in. If so, record their session
            if (isLoggingIn || this.hasSessionExpired()) {
                sessionStorage.setItem(this.sessionKey, "true");
                const ipJSON = await this.axios.get('https://api64.ipify.org?format=json');
                const {data} = await this.axios.get<string>(`/record-session?platform=${checkUserBrowser()}&ipAddress=${ipJSON.data.ip}&isLoggingIn=${isLoggingIn}`);
                return data;
            }
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
        return null;
    }

    // Checks if the session is still valid or expired
    hasSessionExpired(): boolean {
        const lastSessionTime: string | null = sessionStorage.getItem(this.sessionKey);
        return !lastSessionTime;
    }
}

export const authService = new AuthService();